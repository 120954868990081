import React from "react";
import { Script } from "gatsby";

const HeaderScripts = () => {
  return (
    <>
      {/* Vidazoo */}
      <Script
        id="vidazooJS"
        src="//static.vidazoo.com/basev/vwpt.js"
        data-widget-id="627a76c79d0248fca7853d0c"
      />

      {/* Block Through */}
      <Script src="//btloader.com/tag?o=5655014103056384&upapi=true" async />

      {/*CPM Events*/}
      <Script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
          function getCookie(cname) {
            var name = cname + "=";
            var decodedCookie = decodeURIComponent(document.cookie);
            var ca = decodedCookie.split(';');
            for(var i = 0; i <ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
              c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
              return c.substring(name.length, c.length);
            }
            }
            return "";
          }
        `,
        }}
      />
      <Script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
          window.sumCpmEvent = 0;
          window.sumCpmEvent_hb = 0;
          window.sumGA = 0;
          window.bids_added = 0;
          window._winners_calc = {};
          window._auctions_calc = {};
          window._min_uv_goal = 5;
          window._max_uv_goal = 1000;
          if (!isNaN(this.getCookie("sumCpmEvent")) && this.getCookie("sumCpmEvent")) {
            window.sumCpmEvent = parseInt(this.getCookie("sumCpmEvent"));
          } else {
            window.sumCpmEvent = 0;
          }
        `,
        }}
      />
    </>
  );
};

export default HeaderScripts;
